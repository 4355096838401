import img1 from '@/images/LS/1.jpg';
import img2 from '@/images/LS/2.jpg';
import img3 from '@/images/LS/3.jpg';
import img4 from '@/images/LS/4.jpg';
import img5 from '@/images/LS/5.jpg';
import img6 from '@/images/LS/6.jpg';

export default [
  {
    img: img1,
    title: '17 August 2019',
    content: `In the enchanting island of Bali, our paths first crossed, and in that moment, our hearts found a connection like no other.`,
  },
  {
    img: img2,
    title: '23 October 2019',
    content: `Our first date was a moment that would forever change our lives, and as our love deepened, we knew that this was something truly special.`,
  },
  {
    img: img3,
    title: '19 January 2020',
    content: `With joy and excitement, we made our love official, promising our hearts to one another in a bond that could not be broken.`,
  },
  {
    img: img4,
    title: 'Feb 2020',
    content: `But destiny had a plan of its own, calling Devita back to her hometown in Jakarta. `,
  },
  {
    img: img5,
    title: 'June 2020',
    content: `Irwan, with love as his guide, followed the call of his heart all the way to Jakarta.`,
  },
  {
    img: img6,
    title: '3 July 2022',
    content: `And now, in the heart of Jakarta, we take the next step on our journey together, as Irwan and Devita become engaged, ready to embark on a new chapter of our love story.`,
  },

];